import { Layout } from '@cfra-nextgen-frontend/shared';
import { AgGirdCard, AgGirdCardProps } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/HideVerticalScroll.scss';
import {
    agGridGetAllRowsCount,
    agGridGetRenderedRowsCount,
    AgGridThemes,
    checkColumnDefsEqualBasedOnField,
    handleSortIndicatorClick,
    handleSortOrderIndicators,
    keepNoLeftPaddingOnMove,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SendSingleInfiniteRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import {
    ResultsKeys,
    ScreenerDataWithGenericResultsKey,
    ScreenerResearchData,
    Viewdata,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    CellRendererValueProcessorGetter,
    extractFromScreenerData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { useInViewScrollDown } from '@cfra-nextgen-frontend/shared/src/hooks/useInViewScrollDown';
import { useMakeIndependent } from '@cfra-nextgen-frontend/shared/src/hooks/useMakeIndependent';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { ModelUpdatedEvent, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { debounce, isEqual } from 'lodash';
import { Dispatch, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { ColumnDef } from './types';
import { useMediaDataTransformer } from '../../hooks/useMediaDataTransformer';

type AgGridCardInfiniteCSMProps<T> = {
    searchByParams: Parameters<SendSingleInfiniteRequest>[0];
    infiniteRequestParamsConfig: Parameters<SendSingleInfiniteRequest>[1];
    size: number;
    scrollThresholdPx: number;
    setCalculateInView: (calculateInView: ReturnType<typeof useInViewScrollDown>['calculateInView']) => void;
    setResults: (pages?: Array<T>) => void;
    updateSearchByParams: Dispatch<SearchByParams>;
    outerGetCellRendererValueProcessor?: CellRendererValueProcessorGetter;
    autoSizePadding?: AgGirdCardProps['autoSizePadding'];
    tooltipShowDelay?: AgGirdCardProps['tooltipShowDelay'];
    resetColumnsCallback: () => void;
};

const gridTheme = [AgGridThemes.GridThemeV2, 'hide-vertical-scroll', 'ag-panel-top-position-fixed'];
export const defaultMaxWidth = 9999; // disable max width for columns
const bufferSize = 99999;

export const AgGridCardInfiniteCSM = forwardRef(function <T extends ScreenerDataWithGenericResultsKey<ResultsKeys>>(
    {
        searchByParams,
        infiniteRequestParamsConfig,
        size,
        scrollThresholdPx,
        setCalculateInView,
        setResults,
        updateSearchByParams,
        outerGetCellRendererValueProcessor,
        autoSizePadding,
        tooltipShowDelay,
        resetColumnsCallback,
    }: AgGridCardInfiniteCSMProps<T>,
    ref: React.Ref<AgGridReact | null>,
) {
    const columnDefsRef = useRef<Array<ColumnDef>>([]);
    const minWidthRef = useRef<Record<string, number>>({});

    const { sendSingleInfiniteRequest } = useContext(ProjectSpecificResourcesContext);

    const gridRef = useRef<AgGridReact>(null);
    const wasSortingChangedRef = useRef<boolean>(false);

    useImperativeHandle(ref, () => gridRef.current);

    if (!sendSingleInfiniteRequest) {
        throw new Error('sendSingleInfiniteRequest is not provided.');
    }

    const researchScreenerQuery = sendSingleInfiniteRequest<T>(searchByParams, infiniteRequestParamsConfig);

    const mediaTransformedViewData = useMediaDataTransformer<Viewdata>({
        inputData: researchScreenerQuery.data?.pages?.[0]?._viewdata,
    });

    const screenerData: ScreenerResearchData | undefined = useMemo(
        () =>
            researchScreenerQuery.data?.pages?.[0]?.results
                ? {
                      ...researchScreenerQuery.data?.pages?.[0],
                      ...(mediaTransformedViewData ? { _viewdata: mediaTransformedViewData } : {}),
                  }
                : undefined,
        [researchScreenerQuery.data?.pages, mediaTransformedViewData],
    );

    const { minWidths: minWidthDynamic, columnDefs: columnDefsDynamic }: ReturnType<typeof extractFromScreenerData> =
        useMemo(() => {
            if (
                !screenerData ||
                !mediaTransformedViewData
            ) {
                return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
            }

            const extractFromScreenerDataResult = extractFromScreenerData({
                screenerData: screenerData,
                cardName: 'Research Hub Search Results',
                outerGetCellRendererValueProcessor,
                keepNoLeftPadding: true,
            });

            return {
                ...extractFromScreenerDataResult,
                columnDefs: extractFromScreenerDataResult.columnDefs.map((columnDef) => ({
                    ...columnDef,
                    // disable sorting for all columns, the actual sorting should be handled on the backend side
                    comparator: () => 0,
                })),
            };
        }, [outerGetCellRendererValueProcessor, mediaTransformedViewData, screenerData]);

    const columnDefs = useMemo(() => {
        // don't update column Defs, if the set of columns didn't changed
        if (
            columnDefsRef.current.length === 0 ||
            (!isEqual(
                columnDefsRef.current?.map((columnDef) => columnDef.field),
                columnDefsDynamic?.map((columnDef) => columnDef.field),
            ) &&
                columnDefsDynamic.length > 0)
        ) {
            columnDefsRef.current = columnDefsDynamic;
            return columnDefsDynamic;
        }

        return columnDefsRef.current;
    }, [columnDefsDynamic]); // keep this deep compare to avoid unnecessary re-renders

    const minWidths = useMemo(() => {
        if (
            (Object.keys(minWidthRef.current).length === 0 && Object.keys(minWidthDynamic).length > 0) ||
            (!isEqual(minWidthRef.current, minWidthDynamic) && Object.keys(minWidthDynamic).length > 0)
        ) {
            minWidthRef.current = minWidthDynamic;
            return minWidthDynamic;
        }

        return minWidthRef.current;
    }, [minWidthDynamic]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) => {
            return headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName];
        },
        [minWidths],
    );

    const { independentValue: researchScreenerQueryFetchNextPage } = useMakeIndependent({
        valueGetter: () => () => {
            researchScreenerQuery.fetchNextPage();
        },
        defaultValue: () => {},
    });

    const fetchNextPage = useCallback(
        (fetchNextPageOnIsEqual: boolean) => {
            if (!gridRef?.current?.api || !researchScreenerQuery?.data?.pages) {
                return;
            }

            const numberOfPagesLoadedIntoGrid = agGridGetAllRowsCount(gridRef.current) / size;

            if (!Number.isInteger(numberOfPagesLoadedIntoGrid)) {
                // if number of pages is not an integer, it means that the last page with (not a full page) is already loaded, so no need to fetch more rows.
                return;
            }

            if (numberOfPagesLoadedIntoGrid > researchScreenerQuery.data.pages.length) {
                throw new Error(
                    `Unexpected behavior. The number of pages loaded into the grid (${numberOfPagesLoadedIntoGrid}) is greater than the number of pages fetched (${researchScreenerQuery.data?.pages.length}).`,
                );
            }

            if (numberOfPagesLoadedIntoGrid === researchScreenerQuery.data.pages.length) {
                if (fetchNextPageOnIsEqual) {
                    researchScreenerQueryFetchNextPage.current();
                }

                return;
            }

            const rowsCount = agGridGetAllRowsCount(gridRef.current);
            const nextRows = researchScreenerQuery.data?.pages?.[numberOfPagesLoadedIntoGrid]?.results.research;

            // load next page into the grid
            gridRef.current.api.applyTransaction({
                add: nextRows,
            });

            // avoid issue with grid have blank white space and no more rows are rendered
            if (rowsCount + nextRows.length > 480) {
                gridRef.current.api.ensureIndexVisible(rowsCount + nextRows.length - 1, 'bottom');
            }
        },
        [researchScreenerQuery?.data?.pages, size, researchScreenerQueryFetchNextPage],
    );

    const { independentValue: fetchNextPageRef } = useMakeIndependent({
        valueGetter: () => () => {
            if (
                researchScreenerQuery.isFetchingNextPage ||
                researchScreenerQuery.isLoading ||
                !researchScreenerQuery?.data?.pages ||
                !gridRef?.current?.api ||
                researchScreenerQuery?.data?.pages?.length < 1 // skip the first page, as it goes into the grid via the rowsData prop
            ) {
                return;
            }

            fetchNextPage(true);
        },
        defaultValue: () => {},
    });

    const tableElementRef = useRef<HTMLDivElement>(null);
    const thresholdInPxRef = useRef<number>(scrollThresholdPx);

    const { calculateInView, unblockAndCalculateInView } = useInViewScrollDown({
        elementRef: tableElementRef,
        callbackRef: fetchNextPageRef,
        thresholdInPxRef,
    });

    // pass calculateInView to the outer component
    useEffect(() => {
        setCalculateInView(calculateInView);
    }, [setCalculateInView, calculateInView]);

    const getUnblockHandler = useCallback(
        (callback: (length: number) => void) => {
            return (event?: any) => {
                // skip the unblock call if first page is not fetched yet
                if (
                    researchScreenerQuery.isLoading ||
                    researchScreenerQuery.isFetchingNextPage ||
                    !researchScreenerQuery.hasNextPage ||
                    !researchScreenerQuery.data?.pages ||
                    researchScreenerQuery.data.pages.length === 0 ||
                    !gridRef.current ||
                    !gridRef.current.api
                ) {
                    return;
                }

                const numberOfDisplayedRows = agGridGetRenderedRowsCount(gridRef.current);
                const numberOfRowsToDisplay = agGridGetAllRowsCount(gridRef.current);

                if (numberOfRowsToDisplay > numberOfDisplayedRows) {
                    return;
                }

                return callback(researchScreenerQuery.data.pages.length);
            };
        },
        [
            researchScreenerQuery.isLoading,
            researchScreenerQuery.isFetchingNextPage,
            researchScreenerQuery.hasNextPage,
            researchScreenerQuery.data?.pages,
        ],
    );

    const { independentValue: onFirstDataRenderedRef } = useMakeIndependent({
        valueGetter: () =>
            getUnblockHandler((length) => {
                if (length !== 1) {
                    return;
                }

                calculateInView();
            }), // apply only for the first page
        defaultValue: () => () => {},
    });

    const onModelUpdatedCallback = useMemo(() => {
        return debounce((event: ModelUpdatedEvent) => {
            getUnblockHandler((length) => {
                if (length === 1 && wasSortingChangedRef.current === true) {
                    // allow to calculate in view one time if sorting changed (fix issue when zoom value is small)
                    wasSortingChangedRef.current = false;
                    calculateInView();
                }

                // apply for all pages except the first one
                if (length > 1) {
                    unblockAndCalculateInView();
                }
            })(event);

            // handle ag grid columns sorting icon (only icon, the sorting logic supposed to be handled on backend side)
            handleSortOrderIndicators({
                event,
                sortDirection: searchByParams.sortDirection,
                orderBy: searchByParams.orderBy,
            });
        }, 200);
    }, [
        calculateInView,
        getUnblockHandler,
        searchByParams.orderBy,
        searchByParams.sortDirection,
        unblockAndCalculateInView,
    ]);

    useEffect(() => {
        return () => {
            onModelUpdatedCallback.cancel();
        };
    }, [onModelUpdatedCallback]);

    const { independentValue: onModelUpdatedRef } = useMakeIndependent({
        valueGetter: () => onModelUpdatedCallback,
        defaultValue: (() => {}) as any,
    });

    const { independentValue: onSortChangedRef } = useMakeIndependent<(event: SortChangedEvent) => void>({
        valueGetter: () => (event: SortChangedEvent) => {
            handleSortIndicatorClick({
                event,
                wasSortingChangedRef,
                updateSearchByParams,
            });
        },
        defaultValue: () => {},
    });

    // handle new pages from api started from the second page (skip first page)
    useEffect(() => {
        if (
            !gridRef?.current?.api ||
            // don't apply transaction for the first page, as it goes into the grid via the rowsData prop
            !researchScreenerQuery?.data?.pages ||
            researchScreenerQuery.data.pages.length < 2 ||
            researchScreenerQuery.isLoading ||
            researchScreenerQuery.isFetchingNextPage
        ) {
            return;
        }

        // don't apply transaction if the next page is already applied
        fetchNextPage(false);
    }, [
        researchScreenerQuery?.data?.pages,
        researchScreenerQuery.isFetchingNextPage,
        researchScreenerQuery.isLoading,
        fetchNextPage,
    ]);

    // used to set results count, the implementation of the setResultsCount should take care of
    // avoiding unnecessary re-renders
    useEffect(() => {
        if (researchScreenerQuery.data?.pages) {
            setResults(researchScreenerQuery.data.pages);
            return;
        }
        setResults(undefined);
    }, [researchScreenerQuery, setResults]);

    const enableAutoSizeAllColumnsRef = useRef<boolean>(true);

    // keep if calculate on each render
    // when no gridRef is available, allow columns auto size
    // when gridRef is available, allow columns auto size only on the first page
    const numberOfPagesLoadedIntoGrid = gridRef.current ? agGridGetAllRowsCount(gridRef.current) / size : 1;
    useEffect(() => {
        if (enableAutoSizeAllColumnsRef.current) {
            enableAutoSizeAllColumnsRef.current = numberOfPagesLoadedIntoGrid > 0 && numberOfPagesLoadedIntoGrid <= 1;
        }
    }, [numberOfPagesLoadedIntoGrid]);

    const resetColumnsCallbackRef = useRef<() => void>(() => {
        resetColumnsCallback?.();
    });

    return (
        <Grid
            key='agGridCardInfiniteCsmTableElementRef'
            ref={tableElementRef}
            container
            gap={4.4}
            justifyContent='center'>
            <ThemeProvider theme={createTheme()}>
                <Box sx={{ width: '100%' }}>
                    <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
                        <AgGirdCard
                            labelProps={{ width: '100%' }}
                            showDefaultExportButton={false}
                            ref={gridRef}
                            columnDefs={columnDefs}
                            rowMultiSelectWithClick={true}
                            suppressRowClickSelection={true}
                            getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                            labelPanelContainerStyles={{ paddingTop: '36px' }}
                            rowsData={researchScreenerQuery.data?.pages[0]?.results.research}
                            // pass large value to imitate unlimited max number of rows to display
                            maxNumberOfRowsToDisplay={bufferSize}
                            // pass large value to imitate unlimited rows buffer
                            rowBuffer={bufferSize}
                            gridTheme={gridTheme}
                            unlimitedCalculatedHeight
                            // auto size columns only on the first page
                            enableAutoSizeAllColumnsRef={enableAutoSizeAllColumnsRef}
                            onModelUpdatedRef={onModelUpdatedRef}
                            onFirstDataRenderedRef={onFirstDataRenderedRef}
                            onSortChangedRef={onSortChangedRef}
                            fullHeightGrid
                            defaultMaxWidth={defaultMaxWidth}
                            showSideHorizontalScrollIndicators
                            useBrowserVerticalScroll
                            useDragScroll
                            onColumnMovedGetter={keepNoLeftPaddingOnMove}
                            autoSizePadding={autoSizePadding}
                            tooltipShowDelay={tooltipShowDelay}
                            checkColumnDefsEqual={checkColumnDefsEqualBasedOnField}
                            horizontalScrollbarAreaHeight={0}
                            autosizeColumnsConfig={{
                                skipHeader: false,
                                skipHasPinnedColumnsCheck: true,
                            }}
                            suppressHeaderMenuButton={false}
                            resetColumnsCallbackRef={resetColumnsCallbackRef}
                        />
                    </AgGridSelectedRowsContextProvider>
                    {researchScreenerQuery?.isFetchingNextPage && (
                        <Box sx={{ width: '100%' }}>
                            <Layout.Skeleton height='10px' />
                        </Box>
                    )}
                </Box>
            </ThemeProvider>
        </Grid>
    );
}) as <T extends ScreenerDataWithGenericResultsKey<ResultsKeys>>(
    props: AgGridCardInfiniteCSMProps<T> & { ref?: React.Ref<AgGridReact | null> },
) => JSX.Element;
