import OrderIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/order.svg';
import { AgGridCardInfiniteCSM } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCardInfiniteCSM';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { GridViewsPanel, Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { getFiltersReqBody, SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import {
    joinArraysAndGetUniqueValues,
    replaceOldArrayWithNewOne,
} from '@cfra-nextgen-frontend/shared/src/utils/lodashHelpers';
import { Box, SxProps, useMediaQuery } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { getScreenerInfiniteRequestParamsConfig } from 'features/home/components/InfiniteOptions/screener';
import { cloneDeep, isEqual, mergeWith } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SortOptions } from 'utils/enums';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { SearchResultsGridView } from './SearchResultsGridView';
import { SearchResultsTableViewSSR } from './SearchResultsTableViewSSR';
import { SortOrder } from './SortOrder';
import { commonCustomBreakpointsTheme } from 'components/themes/customBreakpointsTheme';

const bestMatchSortOptions = {
    orderBy: undefined,
    sortDirection: undefined,
};

const selectOptionsToSortOptions: Record<SortOptions, { orderBy?: string; sortDirection?: 'asc' | 'desc' }> = {
    [SortOptions.BestMatch]: bestMatchSortOptions,
    [SortOptions.PublishDateAsc]: {
        orderBy: 'research_report.publish_timestamp',
        sortDirection: 'asc',
    },
    [SortOptions.PublishDateDesc]: {
        orderBy: 'research_report.publish_timestamp',
        sortDirection: 'desc',
    },
};

function getResultsCount(count: number) {
    if (count === -1) {
        return 'Loading...';
    }
    return count;
}

const mobileVariantCardHeaderContainerStyles = {
    paddingBottom: '0px',
    borderBottom: '1px solid #eaeaea',
    marginBottom: '8px',
};

function HeaderSectionContainer({
    children,
    selectedView,
    sx,
}: {
    children: React.ReactNode;
    selectedView: Views;
    sx?: SxProps;
}) {
    return (
        <Grid
            container
            sx={{
                margin: `0px ${selectedView === Views.GridView ? 18 : 0}px`,
                ...sx,
            }}>
            {children}
        </Grid>
    );
}

export function SearchResults({
    searchTerm,
    externalPostData,
    defaultFilters,
    defaultSortOption = SortOptions.PublishDateDesc,
    queryKeyFirstElementPostfix,
    externalSearchByParams,
    showOnlyTableView,
    showTopLevelSortOptions = true,
    gridViewItemContainerStyles,
    variant = 'desktop',
    titleRightSlotMobile,
    belowTitleSlotMobile,
    cardTitleOnMobile,
}: {
    searchTerm?: string;
    externalPostData?: Record<string, any>;
    defaultFilters?: Record<string, any>;
    defaultSortOption?: SortOptions | undefined;
    queryKeyFirstElementPostfix?: string;
    externalSearchByParams?: SearchByParams;
    showOnlyTableView?: boolean;
    showTopLevelSortOptions?: boolean;
    gridViewItemContainerStyles?: SxProps;
    variant?: 'desktop' | 'mobile';
    titleRightSlotMobile?: JSX.Element;
    belowTitleSlotMobile?: JSX.Element;
    cardTitleOnMobile: string;
}) {
    const calculateInViewRefTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [selectedView, setSelectedView] = useState<Views>(showOnlyTableView ? Views.TableView : Views.GridView);
    const [selectedSortOption, setSelectedSortOption] = useState<SortOptions | undefined>(defaultSortOption);

    const _externalPostData = useMemo(() => externalPostData || {}, [externalPostData]);

    const isBelowMd = useMediaQuery(commonCustomBreakpointsTheme.breakpoints.down('md'));

    const { filtersPostData } = useContext(FiltersModalContext);

    const isMobileVariant = useMemo(() => variant === 'mobile', [variant]);

    const size = useMemo(() => {
        if (!externalSearchByParams?.size) {
            throw new Error('externalSearchByParams.size is required in SearchResults');
        }

        return externalSearchByParams.size;
    }, [externalSearchByParams]);

    // take 50% of fetching size, assume each item height is 48px
    const scrollThresholdPx = useMemo(() => Math.round((48 * size) / 2), [size]);

    const filtersReqBody = useMemo(() => {
        const result = cloneDeep(filtersPostData) || {};
        mergeWith(result, _externalPostData, joinArraysAndGetUniqueValues);
        mergeWith(result, defaultFilters, replaceOldArrayWithNewOne);

        return getFiltersReqBody(result);
    }, [filtersPostData, _externalPostData, defaultFilters]);

    // set initial searchByParams state
    const [searchByParams, _setSearchByParams] = useState<SearchByParams>({
        path: 'research/screener',
        securityType: 'research',
        requestBody: filtersReqBody,
        search: searchTerm,
        ...externalSearchByParams,
        size,
        // if user entered search term - switch the order to best match
        ...(Boolean(searchTerm) && bestMatchSortOptions),
        ...((selectedSortOption && selectOptionsToSortOptions[selectedSortOption]) || {}),
    });

    // handle filters changes and search term changes without unnecessary re-renders
    useEffect(() => {
        _setSearchByParams((oldParams) => {
            const isSearchTermChanged = oldParams.search !== searchTerm;

            if (isSearchTermChanged) {
                // if user entered search term - switch the order to best match
                setSelectedSortOption(SortOptions.BestMatch);
            }

            const newParams = {
                ...oldParams,
                requestBody: filtersReqBody,
                search: searchTerm,
                // if user entered search term - switch the order to best match
                ...(searchTerm && isSearchTermChanged && bestMatchSortOptions),
                size,
            };

            if (isEqual(oldParams, newParams)) {
                return oldParams;
            }

            return newParams;
        });
    }, [filtersReqBody, searchTerm, size]);

    // create a function to update searchByParams state without unnecessary re-renders
    const updateSearchByParams = useCallback((_newParams: SearchByParams) => {
        _setSearchByParams((oldParams) => {
            const newParams = {
                ...oldParams,
                ..._newParams,
            };

            if (isEqual(oldParams, newParams)) {
                return oldParams;
            }

            const newSelectOption = Object.entries(selectOptionsToSortOptions).find(([selectOption, sortOptions]) => {
                return (
                    sortOptions.orderBy === newParams.orderBy && sortOptions.sortDirection === newParams.sortDirection
                );
            });

            if (newSelectOption) {
                setSelectedSortOption(newSelectOption?.[0] as SortOptions);
            } else {
                // display the selected sort option as "Selected Sort" if the sort option is not in the select options
                setSelectedSortOption(undefined);
            }

            return newParams;
        });
    }, []);

    // handle sort select changes without unnecessary re-renders
    useEffect(() => {
        _setSearchByParams((oldParams) => {
            const newParams = {
                ...oldParams,
                ...((selectedSortOption && selectOptionsToSortOptions[selectedSortOption]) || {}),
            };

            if (isEqual(oldParams, newParams)) {
                return oldParams;
            }

            return newParams;
        });
    }, [selectedSortOption]);

    const {
        chipStateManager: {
            chipStateDispatcher,
            chipState: { resultCount },
        },
    } = useContext(ResultsContext);

    const gridRef = useRef<AgGridReact>(null);

    const calculateInViewRef = useRef<() => void>();

    const setResultsCount = useCallback(
        (newResultCount: number) => {
            // skip this check on the table view mobile devices, this doesn't work there with this check
            if (newResultCount === resultCount || (newResultCount < 0 && resultCount < 0)) {
                return;
            }

            chipStateDispatcher({
                type: 'SetResultCount',
                newState: {
                    resultCount: newResultCount,
                },
            });
        },
        [chipStateDispatcher, resultCount],
    );

    const setResultsCountRef = useRef(setResultsCount);

    const handleResultsCount: (pages?: Array<ScreenerResearchData>) => void = useCallback(
        (pages) => {
            if (!pages) {
                setResultsCount(-1);
                return;
            }

            setResultsCount(pages[0]?.results?.total);
        },
        [setResultsCount],
    );

    const extendedScreenerInfiniteRequestParamsConfig = useMemo(
        () => ({
            // need also include size value here, as with changing the size value the infinite options the different cache should be used
            queryKeyFirstElement: `researchScreenerQuery${queryKeyFirstElementPostfix}${size}`,
            ...getScreenerInfiniteRequestParamsConfig<'research'>(size, 'research'),
        }),
        [size, queryKeyFirstElementPostfix],
    );

    const tableView = useMemo(() => {
        const resetColumnsCallback = () => updateSearchByParams(selectOptionsToSortOptions[defaultSortOption]);

        if (isBelowMd) {
            return (
                <SearchResultsTableViewSSR
                    key={`tableView_${searchByParams.size}_infiniteSSR`}
                    searchByParams={searchByParams}
                    config={{
                        path: searchByParams.path,
                        ...extendedScreenerInfiniteRequestParamsConfig,
                    }}
                    autoSizePadding={defaultAutosizePadding}
                    updateSearchByParams={updateSearchByParams}
                    setResultsCountRef={setResultsCountRef}
                    resetColumnsCallback={resetColumnsCallback}
                />
            );
        }

        return (
            <AgGridCardInfiniteCSM
                key={`tableView_${searchByParams.size}_infiniteCSM`}
                ref={gridRef}
                searchByParams={{
                    ...searchByParams,
                    config: {
                        enabled: true,
                    },
                }}
                infiniteRequestParamsConfig={extendedScreenerInfiniteRequestParamsConfig}
                scrollThresholdPx={scrollThresholdPx}
                setCalculateInView={(calculateInView) => (calculateInViewRef.current = calculateInView)}
                size={size}
                setResults={(data) => handleResultsCount(data)}
                updateSearchByParams={updateSearchByParams}
                outerGetCellRendererValueProcessor={getCellRendererValueProcessor}
                autoSizePadding={defaultAutosizePadding}
                tooltipShowDelay={defaultTooltipShowDelay}
                resetColumnsCallback={resetColumnsCallback}
            />
        );
    }, [
        searchByParams,
        calculateInViewRef,
        updateSearchByParams,
        extendedScreenerInfiniteRequestParamsConfig,
        scrollThresholdPx,
        size,
        handleResultsCount,
        isBelowMd,
        defaultSortOption,
    ]);

    const searchResultsHeader = useMemo(() => {
        return (
            <CardHeaderVariant1
                title={isMobileVariant ? undefined : 'Search Results'}
                subTitle={`${isMobileVariant ? '' : 'Total '}Results: ${getResultsCount(resultCount)}`}
                slot0={
                    !isMobileVariant &&
                    showTopLevelSortOptions && (
                        <img
                            src={OrderIcon}
                            alt='Sort Icon'
                            style={{ width: '16px', height: '16px', marginRight: '13px' }}
                        />
                    )
                }
                slot2Prefix={isMobileVariant ? '' : 'Sort:'}
                slot2={
                    showTopLevelSortOptions ? (
                        <Box sx={isMobileVariant ? undefined: { marginBottom: '-4px' }}>
                            <SortOrder
                                handleSelectionChange={(e) => {
                                    setSelectedSortOption(e.target.value as SortOptions);
                                }}
                                selectedSortOption={selectedSortOption}
                                variant={isMobileVariant ? 'short' : 'normal'}
                                defaultSelectedSortOption={defaultSortOption}
                            />
                        </Box>
                    ) : undefined
                }
                slot3={
                    !showOnlyTableView ? (
                        <GridViewsPanel
                            onClickCallback={(view) => {
                                setSelectedView(view);

                                if (view === Views.TableView) {
                                    if (calculateInViewRefTimeout.current) {
                                        clearTimeout(calculateInViewRefTimeout.current);
                                    }

                                    calculateInViewRefTimeout.current = setTimeout(
                                        () => calculateInViewRef.current?.(),
                                        200,
                                    );
                                }
                            }}
                            defaultViewSelected={selectedView}
                        />
                    ) : undefined
                }
                containerStyles={
                    isMobileVariant
                        ? {
                              paddingBottom: '0px',
                              borderBottom: '1px solid #eaeaea',
                              marginBottom: '8px',
                              background: '#FFFFFF',
                              zIndex: 1,
                          }
                        : {}
                }
            />
        );
    }, [
        resultCount,
        selectedSortOption,
        selectedView,
        showOnlyTableView,
        showTopLevelSortOptions,
        isMobileVariant,
        defaultSortOption,
    ]);

    const cardHorizontalPadding = useMemo(() => {
        return selectedView === Views.GridView ? 6 : 24;
    }, [selectedView]);

    return (
        <ETFCard
            containerStyles={{
                padding: `8px ${cardHorizontalPadding}px 36px ${cardHorizontalPadding}px`,
                transition: 'height 5s ease-out, max-height 5s ease, min-height 5s ease-out,',
                height: 'auto',
                minHeight: 'auto',
                maxHeight: 'auto',
                borderRadius: '8px',
                justifyContent: 'center',
            }}>
            {isMobileVariant && (
                <>
                    <HeaderSectionContainer key='mobileHeader' selectedView={selectedView}>
                        <CardHeaderVariant1
                            title={cardTitleOnMobile}
                            containerStyles={mobileVariantCardHeaderContainerStyles}
                            slot3={titleRightSlotMobile}
                        />
                    </HeaderSectionContainer>
                    {belowTitleSlotMobile && (
                        <HeaderSectionContainer key='mobileBelowHeaderSlot' selectedView={selectedView}>
                            {belowTitleSlotMobile}
                        </HeaderSectionContainer>
                    )}
                </>
            )}
            <HeaderSectionContainer selectedView={selectedView}>{searchResultsHeader}</HeaderSectionContainer>
            {selectedView === Views.GridView && (
                <SearchResultsGridView
                    searchByParams={searchByParams}
                    extendedScreenerInfiniteRequestParamsConfig={extendedScreenerInfiniteRequestParamsConfig}
                    gridViewItemContainerStyles={gridViewItemContainerStyles}
                    handleResultsCount={handleResultsCount}
                    scrollThresholdPx={scrollThresholdPx}
                    optionsContainerSx={
                        isBelowMd
                            ? {
                                  overflowY: 'scroll',
                                  maxHeight: '510px',
                                  ...scrollbarThemeV3,
                              }
                            : undefined
                    }
                    useOuterContainerRef={isBelowMd}
                />
            )}
            {selectedView === Views.TableView && tableView}
        </ETFCard>
    );
}
