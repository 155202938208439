import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridThemes, keepNoLeftPaddingOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { BannerCard } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CommonButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/CommonButton';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import {
    TypographyStyle2,
    TypographyStyle5,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { ApiNames, fontWeights, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, createTheme, Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import BiggestConcernsBanner from 'assets/images/biggest_concerns_banner.svg';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { BadgeBiggestConcerns } from 'components/BadgeBiggestConcerns';
import moment from 'moment';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';

const trendingHeaderStyles = {
    height: '60px',
    padding: '20px',
    alignItems: 'center',
};

const MAX_ROWS_DISPLAY_COUNT = 11;

export const BiggestConcernTitle = ({ titleStyles }: { titleStyles?: SxProps<Theme> }) => {
    return (
        <Stack direction='row' gap='8px' alignItems='center' height='50px'>
            <TypographyStyle2 fontWeight={fontWeights.Medium}>Biggest Concerns</TypographyStyle2>
            <BadgeBiggestConcerns />
        </Stack>
    );
};

const gridTheme = [AgGridThemes.BcGridTheme, 'ag-panel-top-position-fixed'];

export function BiggestConcernsCard() {
    const navigate = useNavigate();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const gridRef = useRef<AgGridReact>(null);
    const betweenMdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));

    const bcScreenerQuery = sendSingleRequest?.(
        {
            path: 'company/screener',
            securityType: 'company',
            view: 'biggest_concerns',
            orderBy: 'frs_biggest_concerns_list.addition_date',
            sortDirection: 'desc',
            requestBody: {
                filters: {
                    values: {
                        'frs_biggest_concerns_list.is_biggest_concern': {
                            values: [true],
                        },
                    },
                },
            },
            config: {},
        },
        {
            requestType: RequestTypes.POST,
            path: 'company/screener',
            queryKeyFirstElement: 'trendingResearchScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!bcScreenerQuery?.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData({
            screenerData: bcScreenerQuery?.data,
            cardName: 'biggest concern',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoLeftPadding: true,
        });
        return result;
    }, [bcScreenerQuery?.data]);

    const bcCompanyList = useMemo(() => {
        const data: any[] = [];
        if (!bcScreenerQuery?.isLoading && bcScreenerQuery?.data?.results?.company) {
            const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
            let bcCompanyList = bcScreenerQuery?.data?.results?.company || [];

            const threeMonthsBcCompanyList = bcCompanyList.filter((bc) => {
                const addition_date = bc?.frs_biggest_concerns_list?.addition_date;
                if (addition_date) {
                    return moment(addition_date).isAfter(threeMonthsAgo);
                }
                return false;
            });

            if (threeMonthsBcCompanyList.length >= 10) {
                return threeMonthsBcCompanyList;
            }

            return bcCompanyList.slice(0, 10);
        }
        return data;
    }, [bcScreenerQuery?.isLoading, bcScreenerQuery?.data?.results?.company]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const handleOnViewAllClick = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    return (
        <BannerCard
            title={<BiggestConcernTitle />}
            bannerImage={BiggestConcernsBanner}
            subHeader={
                <Stack direction='row' gap='8px' alignItems='center' justifyContent='space-between'>
                    <Box sx={trendingHeaderStyles}>
                        <TypographyStyle2 fontWeight={fontWeights.Medium} lineHeight='18px'>
                            Latest Additions
                        </TypographyStyle2>
                    </Box>
                    <CommonButton
                        theme={createTheme({})}
                        content={
                            <TypographyStyle5 sx={{ textTransform: 'none', lineHeight: '18px' }}>
                                View All
                            </TypographyStyle5>
                        }
                        onClickCallback={() => handleOnViewAllClick('/accounting-lens/biggest-concern')}
                        outlined={true}
                        sx={{ padding: '6px 14px', justifyContent: 'space-between', marginRight: '20px' }}
                    />
                </Stack>
            }
            isLoading={bcScreenerQuery?.isLoading}
            noResults={(bcCompanyList?.length || 0) === 0}
            containerStyles={
                bcCompanyList.length > MAX_ROWS_DISPLAY_COUNT ? { height: '100%', width: '100%' } : { width: '100%' }
            }
            childrenContainerSx={{ margin: '0 8px 10px 20px', height: '100%' }}>
            {!bcScreenerQuery?.isLoading && (bcCompanyList?.length || 0) > 0 && (
                <AgGirdCard
                    showDefaultExportButton={false}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    suppressRowClickSelection={true}
                    customFlexibleColumns={customFlexibleColumns}
                    getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                    rowsData={bcCompanyList || []}
                    gridTheme={gridTheme}
                    maxNumberOfRowsToDisplay={betweenMdAndLg ? 12 : MAX_ROWS_DISPLAY_COUNT}
                    unlimitedCalculatedHeight
                    useDragScroll
                    onColumnMovedGetter={keepNoLeftPaddingOnMove}
                    autoSizePadding={defaultAutosizePadding}
                    tooltipShowDelay={defaultTooltipShowDelay}
                    autosizeColumnsConfig={{
                        skipHeader: false,
                        skipHasPinnedColumnsCheck: true,
                    }}
                    suppressHeaderMenuButton={false}
                />
            )}
        </BannerCard>
    );
}
