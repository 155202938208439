import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import {
    useFiltersForm,
    UseFiltersFormExternalInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { SearchInputVariant1Ref } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1';
import { Title } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTitles';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { ExportButton } from 'components/watchlists/ExportButton';
import { useWatchlistActions } from 'components/watchlists/hooks/useWatchlistActions';
import { cloneDeep, debounce } from 'lodash';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { companyFiltersRequestParams } from 'utils/api';
import { getFiltersJsx, SearchCompanyFilter } from './getFiltersJsx';
import { ToggleOptions } from './utils';
import { AgGridThemes, keepNoLeftPaddingOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { Toggle, ToggleRef } from '@cfra-nextgen-frontend/shared/src/components/Toggle/ToggleVariant1';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { ScreenerChipThemeOutline } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { Collapse, IconButton, ThemeProvider, useMediaQuery } from '@mui/material';
import { FiltersButtonWithModal } from 'components/ResearchComponent/components/filters/FiltersButtonWithModal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ScoresInfoBanner } from '../ScoresInfoBanner';
import { PageTheme } from 'components/themes/theme';
import {
    customBreakpointTheme,
    customBreakPointValues,
} from '@cfra-nextgen-frontend/shared/src/components/themes/theme';

const styles = {
    xsGridStandardStyle: {
        width: '100%',
        marginBottom: '15px',
        paddingBottom: '10px',
    },
};

const size = 25;
const sendSingleRequestConfig = {
    path: 'company/screener',
    apiName: ApiNames.Research,
    requestType: RequestTypes.POST,
    queryKeyFirstElement: 'researchCompanyScreenerQuery',
};

type ScoresCardProps = {
    watchlist: Omit<ReturnType<typeof useWatchlistActions>, 'unselectWatchlist'>;
    externalFilters?: Record<string, any>;
    externalChipItems?: Record<string, ChipItem>;
    onExternalChipDeleteClick?: UseFiltersFormExternalInputProps['onExternalChipDeleteClick'];
};

const cashFlowFilterMetadataKey = 'frs_cash_flow_score_drivers.decile';
const earningsFilterMetadataKey = 'frs_earnings_score_drivers.decile';

export type ScoresCardRefType = {
    resetToggle: () => void;
} & SearchInputVariant1Ref;

const gridTheme = [AgGridThemes.GridThemeV2, 'ag-panel-top-position-fixed'];

export const ScoresCard = forwardRef<ScoresCardRefType, ScoresCardProps>(
    (
        {
            watchlist: {
                createWatchlistButtonJsx,
                watchlistSelectJsx,
                operationsModalsJsx,
                selectedWatchlist,
                watchlistOperationsMenuIconJsx,
            },
            externalFilters,
            externalChipItems,
            onExternalChipDeleteClick,
        },
        ref,
    ) => {
        const { sendSingleRequest, getDataSource, getSsrDataExportFn, exportSSRAgGrid } = useContext(
            ProjectSpecificResourcesContext,
        );
        const [selectedToggleOption, setSelectedToggleOption] = useState<ToggleOptions>();
        const [searchTerm, setSearchTerm] = useState<string>();
        const isMobileVariant = useMediaQuery(customBreakpointTheme.breakpoints.down('sm'));
        const [expandedTopic, setExpandedTopic] = useState<boolean>();

        if (!sendSingleRequest) {
            throw new Error('sendSingleRequest is not defined');
        }

        if (!getDataSource) {
            throw new Error('getDataSource is not defined');
        }

        if (!getSsrDataExportFn) {
            throw new Error('getSsrDataExportFn is not defined');
        }

        if (!exportSSRAgGrid) {
            throw new Error('exportSSRAgGrid is not defined');
        }

        const clearSearchRef = useRef<SearchInputVariant1Ref>(null);
        const toggleRef = useRef<ToggleRef>(null);

        useImperativeHandle(ref, () => ({
            clearSearch: () => clearSearchRef.current?.clearSearch(),
            resetToggle: () => toggleRef.current?.resetToggle(),
        }));

        const searchInputOnChange = useMemo(() => {
            return debounce((e) => setSearchTerm(e.target.value), 200);
        }, []);

        useEffect(() => {
            return () => {
                searchInputOnChange.cancel();
            };
        }, [searchInputOnChange]);

        const togglePropsObj = useMemo(
            () => ({
                options: Object.values(ToggleOptions),
                defaultSelectionIndex:
                    selectedToggleOption !== undefined
                        ? Object.values(ToggleOptions).findIndex((o) => o === selectedToggleOption)
                        : Object.values(ToggleOptions).indexOf(ToggleOptions.All),
                onChange: (index: number) => setSelectedToggleOption(Object.values(ToggleOptions)[index]),
                ref: toggleRef,
            }),
            [selectedToggleOption],
        );

        const searchInputProps = useMemo(
            () => ({
                onChange: searchInputOnChange,
                clearButtonCallback: () => setSearchTerm(undefined),
                placeholder: 'Company Search',
                ref: clearSearchRef,
            }),
            [searchInputOnChange],
        );

        const memoisedGetFiltersJsx = useMemo(() => {
            return getFiltersJsx({
                toggleProps: {
                    ...togglePropsObj,
                    onChange: (index: number) => {
                        if (isMobileVariant) togglePropsObj.ref.current?.externalSetSelectedOptionIndex(index);
                        togglePropsObj.onChange(index);
                    },
                },
                searchInputProps,
                isMobileVariant,
            });
        }, [isMobileVariant, searchInputProps, togglePropsObj]);

        const { filtersFormJsx, filtersChipPanelJsx, filtersData, submitHandler, control } = useFiltersForm({
            filtersRequestParams: companyFiltersRequestParams,
            getFiltersJsx: memoisedGetFiltersJsx,
            externalChipItems,
            onExternalChipDeleteClick,
            chipStyles: {
                containerSx: { padding: isMobileVariant ? '18px 14px 15px 14px' : '0', maxHeight: 'unset' },
                onChipItemsExistSxProps: {
                    borderTop: '1px dotted #CCCCCC',
                    paddingTop: '24px',
                    paddingBottom: '15px',
                    overflowY: 'scroll',
                },
            },
            formStyle: {
                marginBottom: '24px',
            },
            useSubmitInFilters: !isMobileVariant,
            chipTheme: isMobileVariant ? ScreenerChipThemeOutline : undefined,
        });

        const metadataKeyToAllValues = useMemo(() => {
            return Object.keys(filtersData?.data || {}).reduce((acc, metadataKey) => {
                acc[metadataKey] = filtersData?.data?.[metadataKey]?.items?.map((item) => item.key) || [];
                return acc;
            }, {} as Record<string, Array<string> | Array<number>>);
        }, [filtersData]);

        const gridRef = useRef<AgGridReact>(null);

        const { filtersPostData } = useContext(FiltersModalContext);

        const requestBody = useMemo(() => {
            let updatedFiltersPostData = cloneDeep(filtersPostData) || {};

            switch (selectedToggleOption) {
                case ToggleOptions.All:
                    // if all selected - filter by both columns with the same values
                    if (Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[earningsFilterMetadataKey] =
                            updatedFiltersPostData[cashFlowFilterMetadataKey];
                    }
                    break;
                case ToggleOptions.EarningsScore:
                    // if earnings selected - filter values in easrnings column to show only numbers (no '-' values)
                    if (!Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[cashFlowFilterMetadataKey] = {
                            values: metadataKeyToAllValues[earningsFilterMetadataKey],
                        };
                    }
                    // move cash flow filter to earnings filter
                    updatedFiltersPostData[earningsFilterMetadataKey] =
                        updatedFiltersPostData[cashFlowFilterMetadataKey];
                    // make sure that cash flow filter is not applied
                    delete updatedFiltersPostData[cashFlowFilterMetadataKey];
                    break;
                case ToggleOptions.CashFlow:
                    // is cash flow selected - filter values in cash flow column to show only numbers (no '-' values)
                    if (!Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[cashFlowFilterMetadataKey] = {
                            values: metadataKeyToAllValues[cashFlowFilterMetadataKey],
                        };
                    }
                    // make sure that earnings filter is not applied
                    delete updatedFiltersPostData[earningsFilterMetadataKey];
                    break;
            }

            return getFiltersReqBody({ ...updatedFiltersPostData, ...externalFilters });
        }, [filtersPostData, externalFilters, selectedToggleOption, metadataKeyToAllValues]);

        const screenerCommonSearchByParams = useMemo(() => {
            return {
                securityType: 'research',
                view: 'scores',
                requestBody: requestBody,
                search: searchTerm,
            };
        }, [requestBody, searchTerm]);

        const searchByParams = useMemo(() => {
            return {
                ...screenerCommonSearchByParams,
                size,
                config: {
                    enabled: true,
                },
            };
        }, [screenerCommonSearchByParams]);

        const companiesQuery = sendSingleRequest(
            searchByParams,
            sendSingleRequestConfig,
        ) as UseQueryResult<ScreenerResearchCompanyData>;

        const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
            if (!companiesQuery.data) {
                return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
            }

            let result = extractFromScreenerData({
                screenerData: companiesQuery.data,
                cardName: selectedWatchlist?.name || 'inst_watchlist',
                outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
                keepNoLeftPadding: true,
                breakpointsConfig: [
                    {
                        from: 0,
                        to: customBreakPointValues.xl,
                        isActive: isMobileVariant,
                    },
                ],
            });

            switch (selectedToggleOption) {
                case ToggleOptions.All:
                    break;
                case ToggleOptions.CashFlow:
                    result.columnDefs = result.columnDefs.filter((columnDef) => {
                        if (columnDef.field === earningsFilterMetadataKey) {
                            return false;
                        }
                        return columnDef;
                    });
                    break;
                case ToggleOptions.EarningsScore:
                    result.columnDefs = result.columnDefs.filter((columnDef) => {
                        if (columnDef.field === cashFlowFilterMetadataKey) {
                            return false;
                        }
                        return columnDef;
                    });
                    break;
            }

            return result;
        }, [companiesQuery, selectedWatchlist, selectedToggleOption, isMobileVariant]);

        const getResizableMinWidthForColumn = useCallback(
            (headerName: string) =>
                headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
            [minWidths],
        );

        const ssrDataSource = useMemo(() => {
            return getDataSource({
                metadataFields: companiesQuery?.data?._metadata.fields || [],
                etfData: companiesQuery?.data?.results?.company || [],
                requestParams: {
                    path: 'company/screener',
                    ...screenerCommonSearchByParams,
                },
                _resultsKey: 'company',
                size,
            });
        }, [
            companiesQuery?.data?._metadata.fields,
            companiesQuery?.data?.results?.company,
            getDataSource,
            screenerCommonSearchByParams,
        ]);

        const totalResultsText = (function () {
            const totalResults = companiesQuery?.data?.results?.total;
            return !companiesQuery?.isLoading && !totalResults
                ? 'Total Results: No records found'
                : `Total Results: ${companiesQuery?.isLoading ? 'Loading...' : totalResults}`;
        })();

        const SSRDataExportFn = useMemo(
            () =>
                getSsrDataExportFn?.({
                    metadataFields: companiesQuery?.data?._metadata.fields || [],
                    requestParams: {
                        path: 'company/screener',
                        ...screenerCommonSearchByParams,
                    },
                    _resultsKey: 'company',
                }),
            [companiesQuery?.data?._metadata.fields, getSsrDataExportFn, screenerCommonSearchByParams],
        );

        const exportCallback = useCallback(() => {
            exportSSRAgGrid(
                gridRef,
                SSRDataExportFn,
                {
                    autoSizeColumns: true,
                    fileName: 'CFRA Scores',
                    metadata: {
                        cardName: 'CFRA Scores',
                    },
                    sheetName: 'Data Details',
                    useMaxAsOfDate: undefined,
                    getFormattedValuesFirst: true,
                },
                companiesQuery?.data?._metadata.fields || [],
            );
        }, [companiesQuery?.data?._metadata.fields, gridRef, exportSSRAgGrid, SSRDataExportFn]);

        return (
            <ETFCard containerStyles={{ paddingBottom: '0px', paddingTop: '22px' }}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        ...styles.xsGridStandardStyle,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: isMobileVariant ? '1px dotted #CCCCCC' : 'none',
                    }}>
                    <Title
                        sx={{
                            lineHeight: 1,
                            paddingBottom: '0',
                        }}>
                        {isMobileVariant ? 'Scores' : 'Filter'}
                    </Title>
                    <ExportButton
                        onClick={() => exportCallback()}
                        sx={{
                            marginLeft: 'auto',
                        }}
                    />
                    {isMobileVariant && (
                        <Grid marginLeft={'10px'}>
                            <FiltersButtonWithModal
                                filtersFormJsx={filtersFormJsx}
                                filtersChipPanelJsx={filtersChipPanelJsx}
                                externalChipItems={undefined}
                                submitHandler={submitHandler}
                                control={control}
                                filterButtonTextStyle={{
                                    color: '#002B5A',
                                    fontSize: '13.5px',
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
                {isMobileVariant && (
                    <Grid
                        sx={{
                            ...styles.xsGridStandardStyle,
                            borderBottom: isMobileVariant ? '1px dotted #CCCCCC' : 'none',
                        }}>
                        <Grid
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            onClick={() => setExpandedTopic(!expandedTopic)}>
                            <Title sx={{ paddingBottom: '0', fontSize: '14px' }}>
                                {'Understanding CFRA Financial Metrics'}
                            </Title>
                            <IconButton>
                                {expandedTopic ? (
                                    <RemoveCircleOutlineIcon style={{ color: '#5a5a5a' }} />
                                ) : (
                                    <AddCircleOutlineIcon style={{ color: '#5a5a5a' }} />
                                )}
                            </IconButton>
                        </Grid>

                        <Collapse in={expandedTopic}>
                            <ScoresInfoBanner />
                        </Collapse>
                    </Grid>
                )}
                {isMobileVariant && (
                    <Grid
                        sx={{
                            ...styles.xsGridStandardStyle,
                            width: 'auto',
                        }}>
                        <ThemeProvider theme={PageTheme}>
                            <Toggle {...togglePropsObj} />
                        </ThemeProvider>
                    </Grid>
                )}
                {isMobileVariant && (
                    <Grid
                        xs={12}
                        sx={{
                            ...styles.xsGridStandardStyle,
                        }}>
                        <SearchCompanyFilter searchInputProps={searchInputProps} />
                    </Grid>
                )}
                {!isMobileVariant && filtersFormJsx}
                <Grid item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} paddingBottom={'36px'}>
                    {watchlistSelectJsx}
                    <div style={{ marginLeft: 10 }}>{createWatchlistButtonJsx}</div>

                    {selectedWatchlist && <div>{watchlistOperationsMenuIconJsx}</div>}
                    {operationsModalsJsx}
                </Grid>
                {!isMobileVariant && filtersChipPanelJsx}
                <Grid item xs={12}>
                    <CardHeaderVariant1
                        title={selectedWatchlist?.name}
                        subTitle={totalResultsText}
                        containerStyles={{
                            borderTop: '1px solid #AAAAAA',
                            paddingTop: '13px',
                            paddingBottom: '0px',
                        }}
                    />
                    <AgGridSelectedRowsContextProvider isSSRMEnabled={true}>
                        <AgGirdCard
                            ref={gridRef}
                            useSSRMode
                            getRowID={getRowID}
                            SSRrowsToFetch={size}
                            embedFullWidthRows={true}
                            columnDefs={columnDefs}
                            gridTheme={gridTheme}
                            SSRDataSource={ssrDataSource}
                            showDefaultExportButton={false}
                            suppressRowClickSelection={true}
                            customFlexibleColumns={customFlexibleColumns}
                            getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                            labelProps={{ width: '100%' }}
                            labelPanelContainerStyles={{ paddingTop: '36px' }}
                            rowMultiSelectWithClick={true}
                            useDragScroll
                            onColumnMovedGetter={keepNoLeftPaddingOnMove}
                            autoSizePadding={defaultAutosizePadding}
                            tooltipShowDelay={defaultTooltipShowDelay}
                            showSideHorizontalScrollIndicators
                            autosizeColumnsConfig={{
                                skipHeader: false,
                                skipHasPinnedColumnsCheck: true,
                            }}
                            suppressHeaderMenuButton={false}
                        />
                    </AgGridSelectedRowsContextProvider>
                </Grid>
            </ETFCard>
        );
    },
);
