export interface WatchlistPreferences {
    last_viewed_id?: number
}

export interface HomepagePreferences {
    company_reports_research_type_ids?: number[]
    industry_research_gics_sector?: string[]
    last_viewed_watchlist_id?: string[]
}

export interface ApplicationPreferences {
    watchlist?: WatchlistPreferences
    homepage?: HomepagePreferences
    research_hub?: WatchlistPreferences
}

export type Preferences = {
    preferences?: ApplicationPreferences
}

export type UserPreferences = Preferences

export enum PreferenceType {
    ResearchHubLastVisited = 'researchHub_lastVisited',
    WatchlistLastVisited = 'Watchlist_LastVisited',
    HomepageLastViewedWatchlist = 'Homepage_LastViewed_Watchlist',
    HomepageCompanyReportsResearchTypeIds = 'Homepage_CompanyReports_ResearchTypeIds',
    HomepageIndustryResearchGicsSector = 'Homepage_IndustryResearch_GicsSector',
}