import copy from '@cfra-nextgen-frontend/shared/src/assets/icons/copy.svg';
import remove from '@cfra-nextgen-frontend/shared/src/assets/icons/remove.svg';
import rename from '@cfra-nextgen-frontend/shared/src/assets/icons/rename.svg';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import { DeleteSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/DeleteSavedItemModal';
import { DuplicateSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/DuplicateSavedItemModal';
import { RenameSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/RenameSavedItemModal';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApplicationType, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { CSSProperties, Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CreateWatchlist } from '../CreateWatchlist';
import { UpdateWatchlistBySearch } from '../UpdateWatchlistBySearch';
import { WatchlistRef, WatchlistSelect } from '../WatchlistSelect';
import {
    MenuVariant3,
    menuVariant3ItemStyles,
} from '@cfra-nextgen-frontend/shared/src/components/ETFMenu/MenuVariant3';
import { Box } from '@mui/material';

export type UseWatchlistActionsInputProps = {
    onWatlistSelectionChanged?: Dispatch<SavedItemTypes | undefined>;
    allowNoWatchlistSelected?: boolean;
    noWatchlistSelectedPlaceholder?: string;
    applyUserPreferences?: boolean;
    showResetOption?: boolean;
    menuIconContainerSx?: CSSProperties;
};

type UseWatchlistActionsOutputProps = {
    // contains jsx of rename/delete/copy watchlist modals
    operationsModalsJsx: JSX.Element;
    // containe jsx of watchlist select
    watchlistSelectJsx: JSX.Element;
    // containes jsx of create watchlist button
    createWatchlistButtonJsx: JSX.Element;
    // contains jsx of whatchlist operations menu icon
    watchlistOperationsMenuIconJsx: JSX.Element;
    selectedWatchlist?: SavedItemTypes;
    unselectWatchlist: () => void;
    // contains jsx of search input for adding items to watchlist
    addItemsSearchInputBoxJsx?: JSX.Element;
};

export function useWatchlistActions({
    allowNoWatchlistSelected,
    noWatchlistSelectedPlaceholder,
    applyUserPreferences = true,
    onWatlistSelectionChanged,
    showResetOption,
    menuIconContainerSx,
}: UseWatchlistActionsInputProps): UseWatchlistActionsOutputProps {
    const [selectedWatchlist, setSelectedWatchlist] = useState<SavedItemTypes>();
    const [renameWatchlist, setRenameWatchlist] = useState<SavedItemTypes>();
    const [deleteWatchlist, setDeleteWatchlist] = useState<SavedItemTypes>();
    const [duplicateWatchlist, setDuplicateWatchlist] = useState<SavedItemTypes>();

    useEffect(() => {
        onWatlistSelectionChanged?.(selectedWatchlist);
    }, [onWatlistSelectionChanged, selectedWatchlist]);

    const { setPreferences } = useUserPreferences({ applicationType: ApplicationType.InstitutionalPortal });

    const watchlistRef = useRef<WatchlistRef>(null);

    async function refetchAllWatchlist(id?: number) {
        await watchlistRef?.current?.refetchAllWatchlist(id);
        setDeleteWatchlist(undefined);
        setRenameWatchlist(undefined);
        setDuplicateWatchlist(undefined);
    }

    const handleDeleteCancelClick = useCallback(() => {
        setDeleteWatchlist(undefined);
    }, []);

    const handleDeleteConfirmClick = useCallback(() => {
        setPreferences(PreferenceType.WatchlistLastVisited, undefined);
        refetchAllWatchlist(deleteWatchlist?.id);
    }, [deleteWatchlist?.id, setPreferences]);

    // containes jsx of create watchlist button
    const createWatchlistButtonJsx = useMemo(() => {
        return <CreateWatchlist onWatchlistCreated={(createdItem) => refetchAllWatchlist(createdItem.id)} />;
    }, []);

    // containe jsx of watchlist select
    const watchlistSelectJsx = useMemo(() => {
        return (
            <WatchlistSelect
                setSelectedWatchlist={setSelectedWatchlist}
                selectedWatchlist={selectedWatchlist}
                ref={watchlistRef}
                allowNoWatchlistSelected={allowNoWatchlistSelected}
                noWatchlistSelectedPlaceholder={noWatchlistSelectedPlaceholder}
                applyUserPreferences={applyUserPreferences}
                showResetOption={showResetOption}
            />
        );
    }, [
        selectedWatchlist,
        allowNoWatchlistSelected,
        applyUserPreferences,
        noWatchlistSelectedPlaceholder,
        showResetOption,
    ]);

    // contains jsx of copy watchlist modal
    const duplicateWatchlistJsx = useMemo(() => {
        return (
            <>
                {typeof duplicateWatchlist?.id === 'number' && (
                    <DuplicateSavedItemModal
                        id={duplicateWatchlist.id}
                        name={duplicateWatchlist.name}
                        savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                        modalTitle='Copy Watchlist'
                        saveType='Save WatchList'
                        onCancel={() => setDuplicateWatchlist(undefined)}
                        onConfirm={(data?: any) => refetchAllWatchlist(data?.id)}
                    />
                )}
            </>
        );
    }, [duplicateWatchlist?.id, duplicateWatchlist?.name]);

    // contains jsx of delete watchlist modal
    const deleteWatchlistJsx = useMemo(() => {
        return (
            <>
                {typeof deleteWatchlist?.id === 'number' && (
                    <DeleteSavedItemModal
                        id={deleteWatchlist.id}
                        name={deleteWatchlist.name}
                        onCancel={handleDeleteCancelClick}
                        onConfirm={handleDeleteConfirmClick}
                    />
                )}
            </>
        );
    }, [deleteWatchlist?.id, deleteWatchlist?.name, handleDeleteCancelClick, handleDeleteConfirmClick]);

    // contains jsx of rename watchlist modal
    const renameWatchlistJsx = useMemo(() => {
        return (
            <>
                {typeof renameWatchlist?.id === 'number' && (
                    <RenameSavedItemModal
                        id={renameWatchlist.id}
                        name={renameWatchlist.name}
                        savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                        modalTitle='Rename Watchlist'
                        saveType='Save WatchList'
                        onCancel={() => setRenameWatchlist(undefined)}
                        onConfirm={() => refetchAllWatchlist(renameWatchlist.id)}
                    />
                )}
            </>
        );
    }, [renameWatchlist?.id, renameWatchlist?.name]);

    const watchlistMenuItems = useMemo(() => {
        return [
            {
                itemName: (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StyledAvatar src={rename} style={menuVariant3ItemStyles.menuItemIcon} />
                        <span style={menuVariant3ItemStyles.menuItemText}>Rename</span>
                    </Box>
                ),
                callback: () => {
                    setRenameWatchlist(selectedWatchlist);
                },
            },
            {
                itemName: (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StyledAvatar src={remove} style={menuVariant3ItemStyles.menuItemIcon} />
                        <span style={menuVariant3ItemStyles.menuItemText}>Delete</span>
                    </Box>
                ),
                callback: () => {
                    setDeleteWatchlist(selectedWatchlist);
                },
            },
            {
                itemName: (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StyledAvatar src={copy} style={menuVariant3ItemStyles.menuItemIcon} />
                        <span style={menuVariant3ItemStyles.menuItemText}>Copy</span>
                    </Box>
                ),
                callback: () => {
                    setDuplicateWatchlist(selectedWatchlist);
                },
            },
        ];
    }, [selectedWatchlist]);

    // contains jsx of whatchlist operations menu icon
    const watchlistOperationsMenuIconJsx = useMemo(() => {
        return <MenuVariant3 menuItems={watchlistMenuItems} containerSx={menuIconContainerSx} />;
    }, [watchlistMenuItems, menuIconContainerSx]);

    const addItemsSearchInputBoxJsx = useMemo(() => {
        return (
            <UpdateWatchlistBySearch
                selectedWatchlist={selectedWatchlist}
                onWatchlistUpdated={(createdItem) => refetchAllWatchlist(createdItem.id)}
            />
        );
    }, [selectedWatchlist]);

    return {
        operationsModalsJsx: (
            <>
                {renameWatchlistJsx}
                {deleteWatchlistJsx}
                {duplicateWatchlistJsx}
            </>
        ),
        watchlistSelectJsx,
        createWatchlistButtonJsx,
        watchlistOperationsMenuIconJsx,
        selectedWatchlist,
        unselectWatchlist: () => watchlistRef.current?.unselectWatchlist(),
        addItemsSearchInputBoxJsx,
    };
}
