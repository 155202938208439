import { Layout } from '@cfra-nextgen-frontend/shared';
import { CFRARoundedSelect } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { SavedItemListResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApplicationType, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { getSavedItemsByTypeConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ResetOption } from 'components/Watchlist/ButtonMenuItem';
import { createTheme, ThemeOptions } from '@mui/material';

export type WatchlistRef = {
    refetchAllWatchlist: (id?: number) => void;
    unselectWatchlist: () => void;
};

type WatchlistSelectProps = {
    selectedWatchlist: SavedItemTypes | undefined;
    setSelectedWatchlist: React.Dispatch<React.SetStateAction<SavedItemTypes | undefined>>;
    allowNoWatchlistSelected?: boolean;
    noWatchlistSelectedPlaceholder?: string;
    applyUserPreferences?: boolean;
    showResetOption?: boolean;
};

const currrentTheme = createTheme();

const customThemeOptions: ThemeOptions = {
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    [currrentTheme.breakpoints.down(480)]: {
                        width: 'calc(100vw - 292px)'
                    }
                },
                input: {
                    paddingRight: '14px !important'
                }
            }
        }
    }
}

export const WatchlistSelect = React.forwardRef<WatchlistRef, WatchlistSelectProps>(
    (
        {
            selectedWatchlist,
            setSelectedWatchlist,
            allowNoWatchlistSelected,
            noWatchlistSelectedPlaceholder,
            applyUserPreferences = true,
            showResetOption,
        },
        ref,
    ) => {
        const { state } = useLocation();
        const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
        const [userWatchlist, setUserWatchlist] = useState<SavedItemTypes[]>();
        const { userPreferences, setPreferences } = useUserPreferences({
            applicationType: ApplicationType.InstitutionalPortal,
        });

        if (!sendSingleRequest) {
            throw new Error('sendSingleRequest is not defined');
        }
        const getAllWatchlistQry = sendSingleRequest<SavedItemListResponseTypes>(
            { types: [UserSavedItemsLookupID.InstitutionalWatchlist] },
            getSavedItemsByTypeConfig,
        );

        const refetchAllWatchlist = async (id?: number) => {
            const { data, isFetching, isError } = await getAllWatchlistQry.refetch();

            if (isFetching || isError) {
                return;
            }

            const currentSelection = data?.data?.find((company) => company.id === id);

            if (currentSelection) {
                setSelectedWatchlist(currentSelection);
                return;
            }

            setSelectedWatchlist(
                data?.data?.sort((a: SavedItemTypes, b: SavedItemTypes) => {
                    return a.name.localeCompare(b.name);
                })?.[0],
            );
        };

        const handleSelectionChange = useCallback(
            (e?: SelectChangeEvent<unknown>) => {
                const _selectedWatchlist = userWatchlist?.find(
                    (p: any) => p.name.toLowerCase() === String(e?.target?.value)?.toLowerCase(),
                );

                if (applyUserPreferences) {
                    setPreferences(PreferenceType.WatchlistLastVisited, _selectedWatchlist?.id);
                }

                setSelectedWatchlist(_selectedWatchlist);
            },
            [setPreferences, setSelectedWatchlist, userWatchlist, applyUserPreferences],
        );

        useImperativeHandle(ref, () => ({
            refetchAllWatchlist,
            unselectWatchlist: () => handleSelectionChange(undefined),
        }));

        useEffect(() => {
            if (
                !getAllWatchlistQry ||
                getAllWatchlistQry.isRefetching ||
                getAllWatchlistQry.isError ||
                !getAllWatchlistQry?.data?.data
            ) {
                return;
            }

            const allUserWatchlists = getAllWatchlistQry?.data?.data?.sort((a: SavedItemTypes, b: SavedItemTypes) => {
                return a.name.localeCompare(b.name);
            });

            setUserWatchlist(allUserWatchlists);

            if (!applyUserPreferences && allowNoWatchlistSelected) {
                return;
            }

            setSelectedWatchlist((_selectedWatchlist) => {
                if (_selectedWatchlist || !userPreferences) {
                    // no state changes in this case
                    return _selectedWatchlist;
                }

                if (state?.homepageViewedWatchlistId) {
                    const homepageViewedWatchlistId = getAllWatchlistQry?.data?.data?.find(
                        (p: any) => p.id === state?.homepageViewedWatchlistId,
                    );
                    if (homepageViewedWatchlistId) {
                        window.history.replaceState({}, '');
                        return homepageViewedWatchlistId;
                    }
                }

                if (!userPreferences?.preferences?.watchlist?.last_viewed_id) {
                    // if no user preferences saved, get first in list as selected watchlist
                    return allUserWatchlists[0];
                }

                const lastViewedWatchlist = getAllWatchlistQry?.data?.data?.find(
                    (p: any) => p.id === userPreferences?.preferences?.watchlist?.last_viewed_id,
                );

                // get last viewed watchlist
                if (lastViewedWatchlist) {
                    return lastViewedWatchlist;
                }

                // Default to first element in list
                return allUserWatchlists[0];
            });
        }, [
            getAllWatchlistQry,
            userPreferences,
            setSelectedWatchlist,
            state?.homepageViewedWatchlistId,
            allowNoWatchlistSelected,
            applyUserPreferences,
        ]);

        const selectItems = useMemo(() => {
            if (!userWatchlist) {
                return [];
            }

            const selectItems = userWatchlist.map((watchlistItem: SavedItemTypes) => watchlistItem.name);

            if (!showResetOption || !selectedWatchlist?.name) {
                return selectItems;
            }

            return [
                {
                    menuItem: (
                        <ResetOption
                            key='reset'
                            onClick={() => {
                                handleSelectionChange(undefined);
                                setSelectedWatchlist(undefined);
                            }}
                        />
                    ),
                },
                ...selectItems,
            ];
        }, [showResetOption, userWatchlist, selectedWatchlist?.name, handleSelectionChange, setSelectedWatchlist]);

        if (userWatchlist === undefined && selectedWatchlist === undefined) {
            return (
                <div style={{ width: '200px', marginLeft: '10px', marginTop: '10px' }}>
                    <Layout.Skeleton height='40px' />
                </div>
            );
        }

        if (!userWatchlist || userWatchlist.length === 0 || (!selectedWatchlist && !allowNoWatchlistSelected)) {
            return <></>;
        }

        return (
            <CFRARoundedSelect
                selectItems={selectItems}
                handleSelectionChange={handleSelectionChange}
                currentSelection={selectedWatchlist?.name || ''}
                placeholder={noWatchlistSelectedPlaceholder}
                themeOptions={customThemeOptions}
            />
        );
    },
);
