import { ReactNode, createContext, useMemo, useState } from 'react';
import { UserPreferences } from '../../types/userPreferences';


export type UserContextProps = {
    userPreferences?: UserPreferences,
    setUserPreferences: React.Dispatch<React.SetStateAction<UserPreferences | undefined>>
}

export const UserContext = createContext<UserContextProps>(
    {} as UserContextProps,
);

export function UserContextProvider({
    children,
}: {
    children: ReactNode;
}) {
    const [userPreferences, setUserPreferences] = useState<UserPreferences>();
    const memorizedChildren = useMemo(() => children, [children]);

    return (
        <UserContext.Provider value={{ userPreferences, setUserPreferences}}>
            {memorizedChildren}
        </UserContext.Provider>
    );
}
