import Analyst from '@cfra-nextgen-frontend/shared/src/assets/icons/analyst.svg';
import BiggestConcern from '@cfra-nextgen-frontend/shared/src/assets/icons/bc.svg';
import BespokeEdge from '@cfra-nextgen-frontend/shared/src/assets/icons/chart-with-human.svg';
import Industry from '@cfra-nextgen-frontend/shared/src/assets/icons/chart.svg';
import CompanyResearch from '@cfra-nextgen-frontend/shared/src/assets/icons/company.svg';
import BespokeServices from '@cfra-nextgen-frontend/shared/src/assets/icons/tree.svg';
import BespokeAdmin from '@cfra-nextgen-frontend/shared/src/assets/icons/user.svg';
import AccountingLensIcon from '@cfra-nextgen-frontend/shared/src/assets/images/AccountingLensIcon.svg';
import BespokeIcon from '@cfra-nextgen-frontend/shared/src/assets/images/BespokeIcon.svg';
import HomeIcon from '@cfra-nextgen-frontend/shared/src/assets/images/HomeIcon.svg';
import LegalEdgeIcon from '@cfra-nextgen-frontend/shared/src/assets/images/LegalEdgeIcon.svg';
import LibraryIcon from '@cfra-nextgen-frontend/shared/src/assets/images/LibraryIcon.svg';
import ScoresIcon from '@cfra-nextgen-frontend/shared/src/assets/images/ScoresIcon.svg';
import WatchlistIcon from '@cfra-nextgen-frontend/shared/src/assets/images/WatchlistIcon.png';
import { TopNavItem } from '@cfra-nextgen-frontend/shared/src/components/TopNavigation/SharedTopNavigation';
import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { LookupItems, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';

export const topNavItems: Record<string, TopNavItem> = {
    Home: {
        name: 'Home',
        href: '/',
        type: 'link',
        icon: HomeIcon,
        length: 45,
    },
    AccountingLens: {
        name: PageNames.AccountingLens,
        href: PageNamesToRoutePaths[PageNames.AccountingLens],
        type: 'tab',
        icon: AccountingLensIcon,
        popup: {
            title: 'Accounting Lens',
            description:
                'Enhance financial decision-making with advanced analytics and insights. Optimize your accounting processes for better performance and compliance.',
            links_title: 'Links',
            links: [
                {
                    icon: BiggestConcern,
                    name: PageNames.AccountingLensBiggestConcern,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensBiggestConcern],
                },
                {
                    icon: CompanyResearch,
                    name: PageNames.AccountingLensCompanyResearch,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensCompanyResearch],
                },
                {
                    icon: Industry,
                    name: PageNames.AccountingLensIndustryResearch,
                    href: PageNamesToRoutePaths[PageNames.AccountingLensIndustryResearch],
                },
                {
                    icon: Analyst,
                    name: 'Analyst Directory',
                    href: PageNamesToRoutePaths[PageNames.AccountingLensAnalystDirectory],
                },
            ],
        },
        length: 127,
    },
    ResearchHub: {
        name: PageNames.ResearchHub,
        href: PageNamesToRoutePaths[PageNames.ResearchHub],
        type: 'link',
        icon: LibraryIcon,
        length: 105,
    },
    Scores: {
        name: PageNames.Scores,
        href: PageNamesToRoutePaths[PageNames.Scores],
        type: 'link',
        icon: ScoresIcon,
        length: 52,
    },
    LegalEdge: {
        name: PageNames.LegalEdge,
        href: PageNamesToRoutePaths[PageNames.LegalEdge],
        type: 'tab',
        icon: LegalEdgeIcon,
        popup: {
            title: 'Legal Edge',
            description:
                'Explore our specialized services and unique research, tailored to your specific needs. Our expert team provides personalized solutions delivering exceptional insights and precision.',
            links_title: 'Links',
            links: [
                {
                    icon: LegalEdgeIcon,
                    name: PageNames.LegalEdge,
                    href: PageNamesToRoutePaths[PageNames.LegalEdge],
                },
                {
                    icon: Analyst,
                    name: 'Analyst Directory',
                    href: PageNamesToRoutePaths[PageNames.LegalEdgeAnalystDirectory],
                },
            ],
        },
        length: 84,
    },
    Bespoke: {
        name: PageNames.Bespoke,
        href: PageNamesToRoutePaths[PageNames.Bespoke],
        type: 'tab',
        icon: BespokeIcon,
        popup: {
            title: 'Bespoke Solutions',
            description:
                'Explore our specialized services and unique research, tailored to your specific needs. Our expert team provides personalized solutions delivering exceptional insights and precision.',
            links_title: 'Links',
            links: [
                {
                    icon: BespokeServices,
                    name: PageNames.BespokeServices,
                    href: PageNamesToRoutePaths[PageNames.BespokeServices],
                },
                { icon: BespokeEdge, name: PageNames.BespokeEdge, href: PageNamesToRoutePaths[PageNames.BespokeEdge] },
                {
                    icon: BespokeAdmin,
                    name: PageNames.BespokeAdmin,
                    href: PageNamesToRoutePaths[PageNames.BespokeAdmin],
                    shouldHideLink: (userEntitlements?: UserEntitlementsResponseData) => {
                        let hide = true;
                        userEntitlements?.packages?.forEach((pckg) => {
                            if (pckg.entitlements) {
                                if (
                                    pckg.entitlements.find(
                                        (ent) =>
                                            ent.item_lid === LookupItems.ResearchTypeId &&
                                            ent.value.toString() === ResearchTypeId.FrsBespokeAdminViewer.toString(),
                                    )
                                ) {
                                    hide = false;
                                    return hide;
                                }
                            }
                        });
                        return hide;
                    },
                },
            ],
        },
        length: 64,
    },
    Watchlist: {
        name: 'Watchlist',
        href: PageNamesToRoutePaths[PageNames.Watchlists],
        type: 'link',
        icon: WatchlistIcon,
        length: 71,
    },
};
