import { LinksRow, LinksRowProps } from '@cfra-nextgen-frontend/shared/src/components/LinksRow/LinksRow';
import { CellRendererValueProcessorGetter } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { fillTemplate } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import { defaultNoResultsSymbol } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { fontFamilies, getValueByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box } from '@mui/material';
import { LinkRetriever } from 'components/AgGrid/LinkRetriever';
import { isNil } from 'lodash';
import { BadgeBiggestConcerns } from 'components/BadgeBiggestConcerns';
import { sortPrimaryFirst } from 'utils/arrays';

export const getCellRendererValueProcessor: CellRendererValueProcessorGetter = ({ fieldViewdata }) => {
    return ({ component, resultValue, param, noResultsSymbol = defaultNoResultsSymbol }) => {
        switch (component) {
            case 'link_with_tooltip':
                if (!Array.isArray(resultValue)) {
                    throw new Error(
                        `The result value for the link_with_tooltip component must be an array. - ${JSON.stringify(
                            resultValue,
                        )}`,
                    );
                }

                if (resultValue.length === 1 && resultValue[0] === noResultsSymbol) {
                    return <>{resultValue[0]}</>;
                }

                if (param?.sort_primary_first) {
                    resultValue = sortPrimaryFirst(resultValue);
                }

                const isBiggestConcern = getValueByPath(resultValue, param?.item_data?.is_biggest_concern_path);

                const linksConfig: LinksRowProps['linksConfig'] = (resultValue as Array<any>).map((item) => {
                    return {
                        text: getValueByPath(item, param?.item_data?.field),
                        link:
                            fillTemplate({
                                templateName: 'link_with_tooltip_link',
                                template: param?.item_data?.link_template || '',
                                dataObject: item,
                            }) || '',
                        styles: param?.link_style,
                        nearTextRightSlot: isBiggestConcern && (
                            <Box sx={{ display: 'flex', marginLeft: '10px', fontWeight: '28px' }}>
                                <BadgeBiggestConcerns />
                            </Box>
                        ),
                        tooltipContent: param?.item_data?.tooltip_template
                            ? fillTemplate({
                                  templateName: 'link_with_tooltip_linksConfig_tooltipContent',
                                  template: param?.item_data?.tooltip_template || '',
                                  dataObject: item,
                              }) || ''
                            : undefined,
                    };
                });

                if (linksConfig.every((link) => !link.text)) {
                    return <>{noResultsSymbol}</>;
                }

                return (
                    <LinksRow
                        tooltipThreshold={param?.tooltip_threshold}
                        toolTipContentComponent={param?.tooltip_content_component}
                        onCrossThresholdText={fillTemplate({
                            templateName: 'link_with_tooltip_onCrossThresholdText',
                            template: param?.on_cross_threshold_template || '',
                            formattedValue: String(linksConfig.length),
                            dataObject: {},
                        })}
                        linksConfig={linksConfig}
                        linkStyle={{
                            color: '#007AB9',
                            textDecoration: 'none',
                            fontFamily: fontFamilies.GraphikRegular,
                            fontSize: '13.5px',
                            lineHeight: '18px',
                            ...param?.link_style,
                        }}
                        linkWrapperClassName={param?.link_wrapper_class}
                    />
                );
            case 'text_array':
                if (!Array.isArray(resultValue)) {
                    throw new Error(
                        `The result value for the text_array component must be an array. - ${JSON.stringify(
                            resultValue,
                        )}`,
                    );
                }

                if (resultValue.length === 1 && resultValue[0] === noResultsSymbol) {
                    return <>{resultValue[0]}</>;
                }

                if (param?.sort_primary_first) {
                    resultValue = sortPrimaryFirst(resultValue);
                }

                resultValue = (resultValue as Array<string>)
                    .map((item) =>
                        getValueByPath(
                            item,
                            param?.item_data?.field,
                            undefined,
                            param?.item_data?.split_by_root_and_field ? true : false,
                        ),
                    )
                    .filter((text) => !isNil(text));

                return <Box className={fieldViewdata?.cell_class}>{resultValue.join(', ')}</Box>;
            case 'open_link':
            case 'svg_icon':
                return (
                    <LinkRetriever
                        type={component}
                        resultValue={resultValue}
                        cellRendererParam={param}
                        iconName={param?.icon}
                    />
                );
            default:
                throw new Error(
                    `The component ${component} is not supported by the screener renderer result child wrapper.`,
                );
        }
    };
};
