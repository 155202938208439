import { AnalystDirectoryElement } from 'features/analystDirectory/routes/AnalystDirectoryRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LegalEdgeHome } from '../components/LegalEdgeHome';

export function LegalEdgeRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route path='/' element={<LegalEdgeHome />} />
            <Route path='/analyst-directory' element={<AnalystDirectoryElement />} />
        </Routes>
    );
}
