import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { ResearchComponent } from 'components/ResearchComponent';
import { SIZE_WHEN_NO_SIDEBAR } from 'components/ResearchComponent/components/constants';
import { gridItemStyle4columns } from 'components/ResearchComponent/components/theme';
import { sectorImageMap } from 'utils/mappings';

const filtersConfig = {
    pillsRowComponentProps: {
        label: 'Sector',
        filterMetadataKey: 'insights.research_report.composite_gics_sector_code',
        imageMap: sectorImageMap,
    },
    virtualizeAutocompleteComponentsProps: [
        {
            defaultInputLabel: 'Sub-Industry',
            filterMetadataKey: 'insights.research_report.composite_gics_sub_industry_code',
        },
        {
            defaultInputLabel: 'Author',
            filterMetadataKey: 'insights.research_report_author.analyst_id',
        },
    ],
    dateRangePickerComponentProps: {
        label: 'Date From/To',
        filterMetadataKey: 'insights.research_report.publish_timestamp',
    },
};

export function BespokeEdgeHome() {
    return (
        <ResearchComponent
            resultsCardTitleOnMobile='Bespoke Edge'
            filtersFormProps={{
                filtersConfig,
            }}
            queriesKeyFirstElementPostfix='bespoke'
            screenerSearchByParams={{
                view: 'research_hub_derivatives',
                size: SIZE_WHEN_NO_SIDEBAR,
            }}
            defaultFilters={{
                'insights.research_report.research_type_id': {
                    values: [ResearchTypeId.FrsBespokeEdge],
                },
            }}
            gridViewItemContainerStyles={gridItemStyle4columns}
        />
    );
}
