import {
    AccordionsSection,
    AccordionsSectionProps,
} from '@cfra-nextgen-frontend/shared/src/components/ControlledAccordions/AccordionsSection';
import { dateRangePickerThemeVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Form/themes/dateRangePicker';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { UseFiltersFormInputProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerFormCheckboxList } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormCheckboxList';
import { ScreenerFormDateRangePicker } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormDateRangePicker';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { filterNameToIcon } from 'utils/mappings';
import { commonCustomBreakpointsTheme } from 'components/themes/customBreakpointsTheme';
import { GetFiltersJsxConfig } from './shared';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { ApplicationType } from '@cfra-nextgen-frontend/shared/src/utils';
import { ManageWatchlist } from 'components/Watchlist/ManageWatchlist';


export type GetModalFiltersJsxProps = GetFiltersJsxConfig & {};

export const getModalFiltersJsx: (props: GetModalFiltersJsxProps) => UseFiltersFormInputProps['getFiltersJsx'] = ({
    pillsRowComponentProps,
    virtualizeAutocompleteComponentsProps,
    dateRangePickerComponentProps,
    theme = commonCustomBreakpointsTheme,
    showIconsOnMobile = true,
    watchlistProps
}) => {
    return ({ submitHandler, control, filtersData, getValues, setValue, validate }) => {
        // allow use hooks inside by returning a component
        function Result() {

            const { userPreferences, setPreferences } = useUserPreferences({
                applicationType: ApplicationType.InstitutionalPortal,
                getPreferencesConfig: { cacheTime: 500 },
            });        
            
            const accordionsSectionOptions = useMemo(() => {
                const result: AccordionsSectionProps['options'] = [];

                if (pillsRowComponentProps) {
                    result.push({
                        label: pillsRowComponentProps.label,
                        icon: showIconsOnMobile ? filterNameToIcon[pillsRowComponentProps.label || ''] : undefined,
                        component: (
                            <ScreenerFormCheckboxList
                                control={control}
                                filtersData={filtersData}
                                filterMetadataKey={pillsRowComponentProps.filterMetadataKey || ''}
                                submitHandler={submitHandler}
                                component={Components.PillsRow}
                                getValues={getValues}
                                setValue={setValue}
                                validate={validate}
                                parentSectionKey={'root'}
                                hide={false}
                            />
                        ),
                    });
                }

                virtualizeAutocompleteComponentsProps.forEach((props) => {
                    result.push({
                        label: props.defaultInputLabel,
                        icon: showIconsOnMobile ? filterNameToIcon[props.defaultInputLabel] : undefined,
                        component: (
                            <ScreenerFormCheckboxList
                                control={control}
                                component={Components.Autocomplete}
                                filtersData={filtersData}
                                filterMetadataKey={props.filterMetadataKey || ''}
                                submitHandler={submitHandler}
                                theme={theme}
                                getValues={getValues}
                                setValue={setValue}
                                validate={validate}
                                parentSectionKey={'root'}
                                hide={false}
                            />
                        ),
                    });
                });
            
                if (watchlistProps && userPreferences && watchlistProps.setWatchlistCompanyIds) {
                    result.push({
                        label: watchlistProps.label,
                        icon: showIconsOnMobile ? filterNameToIcon[watchlistProps.label]: undefined,
                        component: (
                            <ManageWatchlist
                                ref={watchlistProps.componentRef}
                                setPreferences={setPreferences}
                                userPreferences={userPreferences}
                                watchlistCompanyIds={watchlistProps.watchlistCompanyIds}
                                setWatchlistCompanyIds={watchlistProps.setWatchlistCompanyIds}
                                pageName={watchlistProps.pageName}
                            />
                        ),
                    });
                }
             
                if (dateRangePickerComponentProps) {
                    result.push({
                        label: dateRangePickerComponentProps.label,
                        icon: showIconsOnMobile
                            ? filterNameToIcon[dateRangePickerComponentProps.label || '']
                            : undefined,
                        component: (
                            <Box sx={{ maxWidth: '258px' }}>
                                <ScreenerFormDateRangePicker
                                    useFormLabelWithContainer={false}
                                    control={control}
                                    component={Components.DateRangePicker}
                                    filtersData={filtersData}
                                    filterMetadataKey={dateRangePickerComponentProps.filterMetadataKey}
                                    submitHandler={submitHandler}
                                    getValues={getValues}
                                    parentSectionKey={'root'}
                                    setValue={setValue}
                                    validate={validate}
                                    hide={false}
                                    theme={dateRangePickerThemeVariant1}
                                    buttonsText={{ start: 'From', end: 'To' }}
                                    hideCalendarMediaQuery='(max-width:374px)'
                                />
                            </Box>
                        ),
                    });
                }

                return result;
            }, [userPreferences, setPreferences]);

            return <AccordionsSection options={accordionsSectionOptions} />;
        }

        return <Result />;
    };
};
